<template>
  <div class="mdc-data-table mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table" aria-label="Dessert calories">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th v-for="(item, index) in table_header" :key="index" class="mdc-data-table__header-cell" role="columnheader" data-column-id="date" scope="col">{{item}}</th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr v-for="(row, index) in table_body" :key="index" :id="'row'+index" class="mdc-data-table__row">

            <th v-if="row.class[0] == 'broker-history-not-correct'" class="mdc-data-table__cell history-not-correct" scope="row">{{row.data[0]}}</th>
            <th v-else class="mdc-data-table__cell history-correct" scope="row">{{row.data[0]}}</th>

            <th v-if="row.class[0] == 'broker-history-not-correct'" class="mdc-data-table__cell history-not-correct" scope="row">{{row.data[1]}}</th>
            <th v-else class="mdc-data-table__cell history-correct" scope="row">{{row.data[1]}}</th>

            <th v-if="row.class[0] == 'broker-history-not-correct'" v-html="row.data[2]" class="mdc-data-table__cell history-not-correct" scope="row"></th>
            <th v-else v-html="row.data[2]" class="mdc-data-table__cell history-correct" scope="row"></th>

            <th v-html="row.data[3]" class="mdc-data-table__cell" scope="row" @click="_deleteBrokerHistory(index)"></th>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";

import { MDCDataTable } from '@material/data-table';

  export default {
    mixins:[APICaller],
    name: 'Table',
    props: {
      table_header: {type: Array},
      table_body: {type: Array},
      table_obj: {type: Object}
    },
    data() {
      return {
      };
    },
    computed: {
    },
    mounted() {
      const table = new MDCDataTable(document.querySelector('.mdc-data-table'));
    },
    methods: {
      _deleteBrokerHistory: function(index){
        var elem_id = document.getElementById("row"+index).childNodes[3].childNodes[0].id
        var params = new URLSearchParams();
        params.append('item_created', elem_id);

        let success = response=>{
          this.$emit("refresh_table")
        }

        let url = '/broker-simulator/delete'

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('236', 'Error en el borrado.');

        this._postAPICall(url, params, successHandler, failureHandler);

      },
    }

  }
</script>



<style lang="scss">
  @use "@material/data-table/data-table";
  @include data-table.core-styles;

  .trash-icon:hover{
    cursor: pointer;
  }

  .history-not-correct{
    background-color: rgb(233, 188, 188) !important;
  }


  .history-correct{
    background-color: rgb(188, 233, 190) !important;
  }

  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    color: rgba(0, 0, 0, 0.6);
    background-color: #F3F4F7;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
  .mdc-data-table {
    width: 100%;}
  
  .mdc-data-table__row,
  .mdc-data-table__cell {
    background-color: white;
    color: black;}

  .inteligence-artificial .mdc-data-table__row .mdc-data-table__cell {
    color: black;
    font-size: 10px;
    width: 100%;
  }
  
  .inteligence-artificial .mdc-data-table__header-cell {
    font-size: 10px;
  }

  .mdc-data-table__table-container {
    overflow-x: auto; 
  }
  #best-bullish {
    .mdc-data-table__cell {
      background-color: white;
      color: black;}
    .mdc-data-table__table-container {
      height: 210px;
      background: white;
    }
  }

  #last-ipos {
    .mdc-data-table__cell {
      background-color: white;
      color: black;
    }
    .mdc-data-table__table-container {
      height: 260px;
      background: white;
    }
  }

  #hot-values {
    .mdc-data-table__table-container {
      height: 210px;
      background: white;
    }
  }

  #waiting-values {
    .mdc-data-table__table-container {
      max-height: 210px;
      background: white;
    }
  }

</style>
